/* Hide number input arrows */
/* Chrome, Safari, Edge, Opera */
input.hide-arrows::-webkit-outer-spin-button,
input.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input.hide-arrows[type='number'] {
  -moz-appearance: textfield;
  text-align: center;
}

.blink {
  animation: blink normal 2s infinite ease-in-out;
  -webkit-animation: blink normal 2s infinite ease-in-out;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.spin {
  animation: spin normal 0.5s infinite ease-in-out;
  -webkit-animation: spin normal 0.5s infinite ease-in-out;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
